// Import the base framework style
@import '@velaux/theme/dist/next.css';
@import '@velaux/theme/variables.less';

@border-radius-8: 8px;
@F7F7F7: #f7f7f7;
@F9F8FF: #f9f8ff;

@primarycolor: @primary-color;
@dangerColor: rgb(248, 81, 73);
@warningColor: var(--message-warning-color-icon-inline, #fac800);
@colorRED: red;
@colorGREEN: #3fb950;
@colorBLUE: blue;
@colorFFF: #fff;
@color333: #333;
@color1A1A1A: #1a1a1a;
@color595959: #595959;
@colorA6A6A6: #a6a6a6;
@color545454: #545454;

@font-family-common: 'PingFangSC-Regular, sans-serif';

@text-align-left: left;
@text-align-center: center;
@text-align-right: right;

@font-size-12: 12px;
@font-size-14: 14px;
@font-size-16: 16px;
@font-size-18: 18px;
@font-size-20: 20px;

@margin-top-5: 5px;
@margin-top-10: 10px;
@margin-top-15: 15px;
@margin-top-20: 20px;

@margin-bottom-5: 5px;
@margin-bottom-10: 10px;
@margin-bottom-15: 15px;
@margin-bottom-20: 20px;

@padding-left-0: 0;
@margin-left-5: 5px;
@margin-left-10: 10px;
@margin-left-15: 15px;
@margin-left-20: 20px;

@margin-right-5: 5px;
@margin-right-10: 10px;
@margin-right-15: 15px;
@margin-right-20: 20px;
@margin-right-16: 16px;
@margin-right-30: 30px;

@padding-top-5: 5px;
@padding-top-10: 10px;
@padding-top-15: 15px;
@padding-top-20: 20px;
@padding-top-30: 30px;

@padding-bottom-5: 5px;
@padding-bottom-10: 10px;
@padding-bottom-15: 15px;
@padding-bottom-20: 20px;
@padding-bottom-30: 30px;

@padding-left-5: 5px;
@padding-left-10: 10px;
@padding-left-15: 15px;
@padding-left-20: 20px;
@padding-left-30: 30px;
@padding-left-32: 32px;

@padding-right-5: 5px;
@padding-right-10: 10px;
@padding-right-15: 15px;
@padding-right-20: 20px;
@padding-right-30: 30px;

@font-size-14: 14px;
@font-size-16: 16px;
@font-size-18: 18px;
@font-size-20: 20px;

a,
a:visited,
a:link {
  color: @link-color;
}
a:hover {
  color: @hover-color;
}

:root {
  --layout-header-height: 48px;
}

:root {
  --min-layout-width: 1500px;
  --primary-color: @primary-color;
  --warning-color: var(--message-warning-color-icon-inline, #fac800);
  --hover-color: @hover-color;
  --border-default-color: #30363d;
  --success-color: #3fb950;
  --failed-color: #f85149;
  --running-color: #dbab0a;
  --danger-color: @dangerColor;
  --grey-200: #d9dae5;
  --grey-300: #b0b1c4;
  --grey-400: #9293ab;
  --grey-500: #6b6d85;
  --grey-700: #383946;
  --grey-800: #22222a;
  --grey-900: #0b0b0d;
  --red-100: #fbe6e4;
  --red-600: #da291d;
  --red-900: #b41710;

  --orange-600: #ff7b26;

  --font-size-normal: 14px;
  --font-size-large: 20px;
  --font-size-x-large: 24px;
  --font-size-medium: 18px;
  --font-size-small: 12px;

  --spacing-0: 0;
  --spacing-1: 0.125rem;
  --spacing-2: 0.25rem;
  --spacing-3: 0.5rem;
  --spacing-4: 0.75rem;
  --spacing-5: 1rem;
  --spacing-6: 1.25rem;
  --spacing-7: 1.5rem;
  --spacing-8: 2rem;
  --spacing-9: 2.5rem;
  --spacing-10: 3rem;
  --spacing-11: 4rem;
  --spacing-12: 4.5rem;
  --spacing-13: 6rem;
  --spacing-14: 10rem;
  --spacing: var(--spacing-5);

  --padding-common: 16px;

  --elevation-2: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
  --elevation-3: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);

  --btn-pure-primary-bg-color: var(--primary-color);
}

.next-form-item.next-large .next-form-item-label {
  font-size: 14px !important;
}

.margin-top-5 {
  margin-top: @margin-top-5;
}

.margin-top-10 {
  margin-top: @margin-top-10;
}

.margin-top-15 {
  margin-top: @margin-top-15;
}

.margin-top-20 {
  margin-top: @margin-top-20;
}

.margin-bottom-5 {
  margin-bottom: @margin-bottom-5;
}

.margin-bottom-10 {
  margin-bottom: @margin-bottom-10;
}

.margin-bottom-15 {
  margin-bottom: @margin-bottom-15;
}

.margin-bottom-20 {
  margin-bottom: @margin-bottom-20;
}

.margin-left-5 {
  margin-left: @margin-left-5;
}

.margin-left-10 {
  margin-left: @margin-left-10;
}

.margin-left-15 {
  margin-left: @margin-left-15;
}

.margin-left-20 {
  margin-left: @margin-left-20;
}

.margin-right-5 {
  margin-right: @margin-right-5;
}

.margin-right-10 {
  margin-right: @margin-right-10;
}

.margin-right-15 {
  margin-right: @margin-right-15;
}

.margin-right-16 {
  margin-right: @margin-right-16;
}

.margin-right-20 {
  margin-right: @margin-right-20;
}

.margin-right-30 {
  margin-right: @margin-right-30;
}

.padding-top-5 {
  padding-top: @padding-top-5;
}

.padding-top-10 {
  padding-top: @padding-top-10;
}

.padding-top-15 {
  padding-top: @padding-top-15;
}

.padding-top-20 {
  padding-top: @padding-top-20;
}

.padding-top-30 {
  padding-top: @padding-top-30;
}

.padding-bottom-5 {
  padding-bottom: @padding-bottom-5;
}

.padding-bottom-10 {
  padding-bottom: @padding-bottom-10;
}

.padding-bottom-15 {
  padding-bottom: @padding-bottom-15;
}

.padding-bottom-20 {
  padding-bottom: @padding-bottom-20;
}

.padding-bottom-30 {
  padding-bottom: @padding-bottom-30;
}

.padding-left-0 {
  padding-left: @padding-left-0 !important;
}

.padding-left-5 {
  padding-left: @padding-left-5;
}

.padding-left-10 {
  padding-left: @padding-left-10;
}

.padding-left-15 {
  padding-left: @padding-left-15;
}

.padding-left-20 {
  padding-left: @padding-left-20;
}

.padding-left-30 {
  padding-left: @padding-left-30;
}

.padding-left-32 {
  padding-left: @padding-left-32;
}

.padding-right-5 {
  padding-right: @padding-right-5;
}

.padding-right-10 {
  padding-right: @padding-right-10;
}

.padding-right-15 {
  padding-top: @padding-right-15;
}

.padding-right-20 {
  padding-right: @padding-right-20;
}

.padding-right-30 {
  padding-right: @padding-right-30;
}

.padding16 {
  padding: 16px;
}

.font-size-14 {
  font-size: @font-size-14;
}

.font-size-16 {
  font-size: @font-size-16;
}

.font-size-18 {
  font-size: @font-size-18;
}

.font-size-20 {
  font-size: @font-size-20;
}

.border-radius-8 {
  border-radius: @border-radius-8;
}

//layout backgound
.background-F7F7F7 {
  background: @F7F7F7;
}

//app list card background
.background-F9F8FF {
  background: @F9F8FF;
}

.background-FFFFFF {
  background: @colorFFF;
}

.colorFFF {
  color: @colorFFF;
}

.color1A1A1A {
  color: @color1A1A1A;
}

.color595959 {
  color: @color595959;
}

.colorA6A6A6 {
  color: @colorA6A6A6;
}

.color545454 {
  color: @color545454;
}

.color333 {
  color: @color333;
}

.font-family-common {
  font-family: @font-family-common;
}

.width-full {
  width: 100%;
}

.height-24 {
  height: 24px !important;
}

.height150 {
  height: 150px !important;
}

.text-align-left {
  text-align: @text-align-left;
}

.text-align-center {
  text-align: @text-align-center;
}

.text-align-right {
  text-align: @text-align-right;
}

.flexcenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexright {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.next-breadcrumb-item {
  .next-select-trigger {
    min-width: 10px !important;

    .next-select-inner {
      min-width: 10px !important;
      background: none;
      border: none;

      .next-input-text-field {
        display: inline;
        padding: 0;
        font-size: 16px;
        line-height: 2;
      }

      .next-input-control {
        padding-right: 0 !important;
      }
    }
  }
}

.empty-table-wraper {
  table {
    min-height: 400px;
  }
}

.next-breadcrumb .next-breadcrumb-text.activated {
  color: #666 !important;
}

.next-form-item-control .b-design_icon__container,
.next-select .next-select-inner {
  background-color: #fff;
}

.link {
  color: @primary-color;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.dm-panel .dm-content {
  padding: 0 !important;
}

.dm-panels {
  z-index: 8 !important;
}

.dm-panel {
  border-top: none !important;
  box-shadow: none !important;
}

.dm-connector {
  z-index: 3;

  .dm-handle {
    background: @primary-color !important;
  }
}

.dm-canvas {
  background-color: transparent !important;

  .dm-dots {
    display: none !important;
  }
}

// .dm-elements {
//   top: 100px;
// }

.colorRed {
  color: @colorRED;
}

.colorGreen {
  color: @colorGREEN;
}

.colorBlue {
  color: @colorBLUE;
}

a {
  color: @primary-color;
  cursor: pointer;
}

.tableButton {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.circle {
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 4px;
  margin-bottom: 2px;
  line-height: 14px;
  background-color: #6c757d;
  border-radius: 50%;
}

.circle-success {
  background-color: #28a745;
}

.circle-failure {
  background-color: #dc3545;
}

.circle-warning {
  background-color: #ffc107;
}

.next-select {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.customTable.next-table-medium .next-table-cell .next-table-cell-wrapper {
  padding: 16px !important;
  text-align: left !important;
}

.monaco-editor {
  min-height: 400px;
}

.next-btn.next-large {
  padding: 0 16px !important;
}

.next-btn.next-large.isOnlyIcon {
  width: auto !important;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-400 {
  font-weight: 400;
}

.container {
  width: 1200px;
  margin: 0 auto;
}

.full-container {
  width: 100%;
  margin: 0 auto;
}

.inline-block {
  display: inline-block;
}

.next-btn.danger-btn {
  color: @dangerColor !important;
  border: @dangerColor 1px solid !important;
}

.next-btn.next-btn-text.danger-btn {
  border: @dangerColor 0px solid !important;
}
.next-btn.next-btn-text {
  margin-right: @s-2;
  display: inline-flex;
  align-items: center;
}

.danger-btn:hover {
  color: #fff !important;
  background: @dangerColor !important;
}

.danger-icon:hover {
  color: @dangerColor !important;
}

.breadcrumb {
  display: inline-flex;
  align-items: center;
  .next-breadcrumb {
    margin-left: 8px;
    height: auto;
  }
  .next-breadcrumb-text,
  .next-breadcrumb-separator {
    height: 30px;
    font-size: 16px;
    line-height: 30px;
    a {
      color: @primary-color;
    }
  }
}

.next-btn,
.inline-center {
  align-items: center;
}

.dropdown-menu-item {
  svg {
    margin-right: 8px;
  }
  color: var(--grey-500);
}
